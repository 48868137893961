import React, { useMemo, useState } from 'react';
import { faPercent } from '@fortawesome/free-solid-svg-icons';

import BasicField from '@payaca/components/basicField/BasicField';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import ContentPanel from '@payaca/components/contentPanel/ContentPanel';
import CurrencyField from '@payaca/components/currencyField/CurrencyField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import LabelValuePair from '@payaca/components/labelValuePair/LabelValuePair';
import RadioButton from '@payaca/components/radioButton/RadioButton';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import SalesTaxSettingsField from '../salesTaxSettingsField/SalesTaxSettingsField';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import { RegionalStrings } from '@payaca/types/internationalTypes';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { LineItem } from '@payaca/types/lineItemTypes';
import { FormState } from './EditItemControl';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import { calculateLineItemCalculatedPrice } from '@payaca/helpers/lineItemHelper';

import { getLineItem, getRegion, getUserRoles } from '@/utils/stateAccessors';

import { useSelector } from '../../../api/state';
import { useAccount } from '../../../utils/storeHooks';

import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';

import { isNullish } from '@payaca/utilities/guards';

import './EditItemPrice.sass';

enum PriceInputMethod {
  PRICE = 'price',
  PROFIT_PERCENTAGE = 'profitPercentage',
  PROFIT_AMOUNT = 'profitAmount',
}

type Props = {
  lineItemId: LineItem['id'];
  formState: FormState;
  updateFormFields: (changedFields: Partial<FormState>) => void;
};
const EditLineItemPrice = ({
  lineItemId,
  formState,
  updateFormFields,
}: Props) => {
  const userRoles = useSelector(getUserRoles);
  const account = useAccount();
  const region = useSelector(getRegion);
  const lineItem = useSelector((state) => getLineItem(state, lineItemId));

  const taxRates = useSelector((state) => state.taxRates.store);
  const taxRate = useMemo(
    () => (formState?.taxRateId ? taxRates[formState.taxRateId] : null),
    [formState?.taxRateId]
  );

  const taxAmount = useMemo(() => {
    return taxRate?.isIncluded && !taxRate?.isReverseCharge
      ? (lineItem?.calculatedPrice || 0) * ((taxRate?.percentage || 0) / 100)
      : 0;
  }, [lineItem, taxRate]);

  const accountCurrencySymbol = useMemo(
    () => getRegionalTextString(region, RegionalStrings.CURRENCY_SYMBOL),
    [region]
  );

  const userCanEdit = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      LineItemsPermissions.UPDATE_LINE_ITEM,
    ]);
  }, [userRoles]);

  const [priceInputMethod, setPriceInputMethod] = useState<PriceInputMethod>(
    !isNullish(formState.markupPercentage)
      ? PriceInputMethod.PROFIT_PERCENTAGE
      : formState.markupAmount
        ? PriceInputMethod.PROFIT_AMOUNT
        : PriceInputMethod.PRICE
  );

  const [isPersistingMarkupPercentage, setIsPersistingMarkupPercentage] =
    useState(typeof formState.markupPercentage === 'number');
  const [isPersistingMarkupAmount, setIsPersistingMarkupAmount] = useState(
    typeof formState.markupAmount === 'number'
  );
  const [internalMarkupPercentage, setInternalMarkupPercentage] =
    useState<any>(0);
  const [internalMarkupAmount, setInternalMarkupAmount] = useState<any>(0);
  const [showProfitPercentageError, setShowProfitPercentageError] =
    useState(false);

  const lineItemProfitMargin = useMemo(() => {
    const profitAmount =
      typeof lineItem?.predictedMarginAmountExcludingTax === 'number'
        ? lineItem.predictedMarginAmountExcludingTax
        : null;

    return !isNullish(profitAmount)
      ? `${currencyPrice(profitAmount, region)}${
          lineItem?.predictedMarginPercentageExcludingTax
            ? ` (${parseFloat(
                lineItem.predictedMarginPercentageExcludingTax.toFixed(1)
              )}%)`
            : ''
        }`
      : '-';
  }, [lineItem]);

  const calculatedPrice = useMemo(() => {
    return calculateLineItemCalculatedPrice(
      lineItem?.predictedCostExcludingTax || 0,
      formState.markupPercentage,
      formState.markupAmount,
      formState.price
    );
  }, [lineItem, formState]);

  const canUpdatePriceOnly = useMemo(
    () =>
      !userHasRequiredPermission(userRoles, [
        LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_PERCENTAGE,
      ]) &&
      !userHasRequiredPermission(userRoles, [
        LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_AMOUNT,
      ]),
    [userRoles]
  );

  const profitPercentage = useMemo(() => {
    const markupPercentage = isPersistingMarkupPercentage
      ? formState.markupPercentage
      : internalMarkupPercentage;

    // rounded to 5dp profit percentage
    return !isNullish(markupPercentage)
      ? Math.round(
          (markupPercentage / (100 + markupPercentage)) * 100 * 100000
        ) / 100000
      : undefined;
  }, [
    isPersistingMarkupPercentage,
    formState.markupPercentage,
    internalMarkupPercentage,
  ]);

  const showProfitOptions = useMemo(() => {
    // show if has materials or if markup field is set i.e. set auto update profit and delete item materials
    return (
      !!lineItem?.materialIds?.length ||
      !isNullish(formState.markupAmount) ||
      !isNullish(formState.markupPercentage)
    );
  }, [
    lineItem?.materialIds,
    formState.markupAmount,
    formState.markupPercentage,
  ]);

  const cisTotal = useMemo(
    () =>
      formState?.cisDeductionRate && lineItem?.calculatedPrice
        ? lineItem?.calculatedPrice * (formState.cisDeductionRate / 100)
        : 0,
    [formState?.cisDeductionRate, lineItem?.calculatedPrice]
  );

  const priceIncludingTax = useMemo(() => {
    if (!lineItem) return 0;

    return lineItem.calculatedPrice + (taxAmount || 0) - cisTotal;
  }, [lineItem, taxAmount, cisTotal]);

  return (
    <ContentPanel className="edit-item-price">
      <>
        <h3>Item sales price</h3>
        <div>
          <div className="edit-price-wrapper">
            <div className="price-input-method-radio">
              {showProfitOptions && !canUpdatePriceOnly && (
                // only show radio if there are materials and user can edit more than just price
                <RadioButton
                  isSelected={priceInputMethod === PriceInputMethod.PRICE}
                  onClick={() => {
                    setPriceInputMethod(PriceInputMethod.PRICE);
                    updateFormFields({
                      markupAmount: null,
                      markupPercentage: null,
                    });
                    setIsPersistingMarkupPercentage(false);
                    setIsPersistingMarkupAmount(false);
                  }}
                  isDisabled={!userCanEdit}
                />
              )}
              <div>
                <span>Enter price</span>
                {(!showProfitOptions ||
                  canUpdatePriceOnly ||
                  priceInputMethod === PriceInputMethod.PRICE) && (
                  // only show price input if there are no materials or user can only edit price or user has selected price input method
                  <CurrencyField
                    name="price"
                    value={
                      isNullish(formState?.price) ? undefined : formState.price
                    }
                    onChange={updateFormFields}
                    isDisabled={!userCanEdit}
                  />
                )}
              </div>
            </div>

            {showProfitOptions && (
              <>
                <PermissionGuard
                  renderIfHasPermissions={[
                    LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_PERCENTAGE,
                  ]}
                >
                  <div className="price-input-method-radio">
                    <RadioButton
                      isSelected={
                        priceInputMethod === PriceInputMethod.PROFIT_PERCENTAGE
                      }
                      onClick={() => {
                        setPriceInputMethod(PriceInputMethod.PROFIT_PERCENTAGE);
                        updateFormFields({
                          markupAmount: null,
                          markupPercentage: null,
                        });
                        setIsPersistingMarkupPercentage(true);
                        setInternalMarkupPercentage(null);
                      }}
                      isDisabled={!userCanEdit}
                    />
                    <div>
                      <span>Set a profit percentage</span>
                      {priceInputMethod ===
                        PriceInputMethod.PROFIT_PERCENTAGE && (
                        <>
                          <ValidatedFieldWrapper
                            validationResult={{
                              isValid: !showProfitPercentageError,
                              errors: showProfitPercentageError
                                ? ['Profit percentage must be less than 100%']
                                : [],
                            }}
                          >
                            <BasicField
                              name="profitPercentage"
                              value={profitPercentage}
                              onChange={(value) => {
                                const profitFactor =
                                  (+value.profitPercentage || 0) / 100;
                                const profitPercentageError = profitFactor >= 1;
                                setShowProfitPercentageError(
                                  profitPercentageError
                                );

                                const markupPercentage = !profitPercentageError
                                  ? !isNullish(profitFactor) &&
                                    profitFactor !== 1
                                    ? (profitFactor / (1 - profitFactor)) * 100
                                    : undefined
                                  : null;

                                updateFormFields({
                                  markupAmount: null,
                                  markupPercentage: isPersistingMarkupPercentage
                                    ? !isNullish(markupPercentage)
                                      ? markupPercentage
                                      : null
                                    : null,
                                  price: isPersistingMarkupPercentage
                                    ? null
                                    : calculateLineItemCalculatedPrice(
                                        lineItem?.predictedCostExcludingTax ||
                                          0,
                                        markupPercentage,
                                        null,
                                        null
                                      ),
                                });
                                setInternalMarkupPercentage(
                                  !isNullish(markupPercentage)
                                    ? markupPercentage
                                    : null
                                );
                              }}
                              type="number"
                              additionalInputProps={{
                                step: 1,
                              }}
                              isDisabled={!userCanEdit}
                              iconAfter={faPercent}
                            />
                          </ValidatedFieldWrapper>
                          <CheckboxField
                            name="isPersistingMarkupPercentage"
                            value={isPersistingMarkupPercentage}
                            onChange={(value: { [key: string]: boolean }) => {
                              if (value.isPersistingMarkupPercentage) {
                                // persist markup
                                updateFormFields({
                                  price: null,
                                  markupAmount: null,
                                  markupPercentage: internalMarkupPercentage,
                                });
                              } else {
                                // don't persist markup percentage
                                updateFormFields({
                                  price: calculatedPrice,
                                  markupPercentage: null,
                                  markupAmount: null,
                                });
                              }
                              setIsPersistingMarkupPercentage(
                                value.isPersistingMarkupPercentage
                              );
                            }}
                            label={
                              <div className="flex-container flex-contiguous flex-center">
                                <span>Auto update</span>
                                <Tooltip text="When material costs change update my sale price" />
                              </div>
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </PermissionGuard>
                <PermissionGuard
                  renderIfHasPermissions={[
                    LineItemsPermissions.UPDATE_LINE_ITEM_MARKUP_AMOUNT,
                  ]}
                >
                  <div className="price-input-method-radio">
                    <RadioButton
                      isSelected={
                        priceInputMethod === PriceInputMethod.PROFIT_AMOUNT
                      }
                      onClick={() => {
                        setPriceInputMethod(PriceInputMethod.PROFIT_AMOUNT);
                        updateFormFields({
                          markupAmount: null,
                          markupPercentage: null,
                        });
                        setIsPersistingMarkupAmount(true);
                        setInternalMarkupAmount(null);
                      }}
                      isDisabled={!userCanEdit}
                    />
                    <div>
                      <span>{`Set a profit amount (${accountCurrencySymbol})`}</span>
                      {priceInputMethod === PriceInputMethod.PROFIT_AMOUNT && (
                        <>
                          <CurrencyField
                            name="markupAmount"
                            value={
                              isPersistingMarkupAmount
                                ? formState.markupAmount
                                : internalMarkupAmount
                            }
                            onChange={(value) => {
                              updateFormFields({
                                markupPercentage: null,
                                markupAmount: isPersistingMarkupAmount
                                  ? !isNullish(value.markupAmount)
                                    ? value.markupAmount
                                    : null
                                  : null,
                                price: isPersistingMarkupAmount
                                  ? null
                                  : calculateLineItemCalculatedPrice(
                                      lineItem?.predictedCostExcludingTax || 0,
                                      null,
                                      +value.markupAmount,
                                      null
                                    ),
                              });
                              setInternalMarkupAmount(
                                !isNullish(value.markupAmount)
                                  ? value.markupAmount
                                  : null
                              );
                            }}
                            isDisabled={!userCanEdit}
                          />

                          <CheckboxField
                            name="isPersistingMarkupAmount"
                            value={isPersistingMarkupAmount}
                            onChange={(value: { [key: string]: boolean }) => {
                              if (value.isPersistingMarkupAmount) {
                                // persist markup
                                updateFormFields({
                                  price: null,
                                  markupAmount: internalMarkupAmount,
                                  markupPercentage: null,
                                });
                              } else {
                                // don't persist markup percentage
                                updateFormFields({
                                  price: calculatedPrice,
                                  markupPercentage: null,
                                  markupAmount: null,
                                });
                              }
                              setIsPersistingMarkupAmount(
                                value.isPersistingMarkupAmount
                              );
                            }}
                            label={
                              <div className="flex-container flex-contiguous flex-center">
                                <span>Auto update</span>
                                <Tooltip text="When material costs change update my sale price" />
                              </div>
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                </PermissionGuard>
              </>
            )}
          </div>

          {/* price ex tax, vat, totals */}
          <div className="item-totals-wrapper">
            <LabelValuePair
              label={`Item price (ex tax)`}
              value={currencyPrice(lineItem?.calculatedPrice || 0, region)}
              suffixLabelWith=""
            />
            {/* Materials */}
            {showProfitOptions && (
              <>
                <LabelValuePair
                  label={`Materials (ex tax)`}
                  value={
                    typeof lineItem?.predictedCostExcludingTax !== 'number'
                      ? '-'
                      : currencyPrice(
                          lineItem.predictedCostExcludingTax,
                          region
                        )
                  }
                />
                <LabelValuePair
                  label="Profit margin"
                  value={lineItemProfitMargin}
                  className="profit-margin"
                  suffixLabelWith=""
                />
              </>
            )}

            <div className="cis-and-tax">
              {/* cis */}
              {account.isCisSubcontractor && (
                <div className="cis-input">
                  <LabelValuePair
                    label={
                      <>
                        <span>CIS</span>
                        <DropdownField
                          name="cisApplies"
                          onChange={(value) => {
                            updateFormFields({
                              cisDeductionRate: value.cisApplies
                                ? account.cisDeductionRate
                                : null,
                            });
                          }}
                          options={[
                            { label: 'No', value: false },
                            {
                              label: `Yes (${account.cisDeductionRate}%)`,
                              value: true,
                            },
                          ]}
                          value={formState?.cisDeductionRate !== null}
                        />
                      </>
                    }
                    value={`-${currencyPrice(cisTotal, region)}`}
                    suffixLabelWith=""
                  />
                </div>
              )}
              {/* vat */}
              <div className="tax-setting-wrapper">
                <div className="tax-setting-field-wrapper">
                  <SalesTaxSettingsField
                    styleVariant={InputStyleVariant.STANDARD}
                    taxRateId={formState?.taxRateId}
                    onChange={updateFormFields}
                  />
                  <span>{currencyPrice(taxAmount, account.region)}</span>
                </div>
              </div>
            </div>
            {/* Total */}
            <LabelValuePair
              label={`Total sales price`}
              value={currencyPrice(priceIncludingTax, region)}
              className="price-to-customer"
              suffixLabelWith=""
            />
          </div>
        </div>
      </>
    </ContentPanel>
  );
};

export default EditLineItemPrice;
